const get = () => process.env.REACT_APP_ENV;

const isLocal = () => get() === "local";
const isDev = () => get() === "development";
const isProd = () => get() === "production";

const check = () => {
  if (isLocal()) return;
  if (isDev()) return;
  if (isProd()) return;

  throw "Invalid Environment Value";
};

export default {
  get,
  isLocal,
  isDev,
  isProd,
  check,
}
