import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Card({
  isBordered = false,
  className = null,
  children,
  ...restProps
}) {
  return (
    <div
      className={cx(
        "card",
        isBordered && "card-bordered",
        "sp-plan",
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
}

Card.Inner = function ({ className = null, children }) {
  return <div className={cx("card-inner", className)}>{children}</div>;
};

Card.Header = function ({ className = null, children }) {
  return (
    <div
      className={cx("card-header", "border-bottom", className)}
      style={{ background: "#e5e9f2" }}
    >
      {children}
    </div>
  );
};

Card.Body = function ({ className = null, children }) {
  return <div className={cx("card-body", className)}>{children}</div>;
};

Card.Footer = function ({ className = null, children }) {
  return (
    <div className={cx("card-footer", "border-top", className)}>{children}</div>
  );
};

Card.propTypes = {
  /**
   * Add border to card
   */
  isBordered: PropTypes.bool,
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};
