import axios from "axios";
import store from "redux/store";
import {
  showConnectionErrorPopup,
  showErrorPopup,
  show404Popup,
  showForbiddenErrorPopup,
  showRateLimitErrorPopup,
} from "redux/page/action";
import { ErrorReport } from "domain/Error/ErrorReport";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = `Bearer ${auth.access_token}`;

// Also add/ configure interceptors && all the other cool stuff
// instance.interceptors.request...
instance.interceptors.request.use(function (config) {
  const access_token = store.getState().auth.access_token;
  config.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
  return config;
});
instance.interceptors.response.use(undefined, function (err) {
  if (axios.isCancel(err)) {
    return Promise.reject(err);
  }

  if (!err.response) {
    if (!err.status) {
      store.dispatch(showConnectionErrorPopup());
      ErrorReport(err);
    } else if (err.request) {
      store.dispatch(showErrorPopup()); // The request was made but no response was received
      ErrorReport(err);
    } else {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    }
    return Promise.reject(err); // other code has dependency to this to know error happen.
  }

  const status = err.response.status;
  const method = err.config.method;
  const bypass = err.config.__bypass || []; // client can tell this interceptor to not intercept specific http codes

  if (method === "get") {
    if (status === 404 && !bypass.includes(404)) {
      store.dispatch(show404Popup());
    } else if (status === 403 && !bypass.includes(403)) {
      store.dispatch(showForbiddenErrorPopup(err.response.data.message));
    } else if (status === 429 && !bypass.includes(429)) {
      store.dispatch(showRateLimitErrorPopup());
    } else if (status === 400 && !bypass.includes(400)) {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    } else if (status >= 500 && status <= 599) {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    } else {
      throw err;
    }
  }

  if (
    method === "post" ||
    method === "patch" ||
    method === "put" ||
    method === "delete"
  ) {
    if (status === 404 && !bypass.includes(404)) {
      store.dispatch(show404Popup());
    } else if (status === 403 && !bypass.includes(403)) {
      store.dispatch(showForbiddenErrorPopup(err.response.data.message));
    } else if (status === 429 && !bypass.includes(429)) {
      store.dispatch(showRateLimitErrorPopup());
    } else if (status === 400 && !bypass.includes(400)) {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    } else if (status >= 500 && status <= 599) {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    } else {
      throw err;
    }
  }

  return Promise.reject(err); // other code has dependency to this to know error happen.
});

export default instance;
