import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { hideConnectionErrorPopup } from "redux/page/action";
import usePreventModalScrollCollision from "hooks/usePreventModalScrollCollision";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export default function NoInternet() {
  usePreventModalScrollCollision();
  const dispatch = useDispatch();
  return (
    <Container>
      <div className="nk-content ">
        <div className="nk-block nk-block-middle wide-xs mx-auto">
          <div className="nk-block-content nk-error-ld text-center">
            <h1 className="nk-error-head">
              <em className="ni ni-wifi-off"></em>
            </h1>
            <h3 className="nk-error-title">Masalah Terhubung ke Server</h3>
            <p className="nk-error-text">
              Pastikan jaringan internet kamu lancar.
            </p>
            <button
              type="button"
              onClick={() => dispatch(hideConnectionErrorPopup())}
              className="btn btn-lg btn-primary mt-2"
            >
              Tutup Pesan
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
