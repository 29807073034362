import React from "react";
import PropTypes from "prop-types";
import env from "domain/Environment/Env";

// This react app will accessed by multiple Dinas domain and dinas want the webapp have no "edulogy things"
export default function EdulogyOnly({ children }) {
  // if (!env.isLocal()) return children;

  if (isEdulogyDomain()) {
    return children;
  }
  return null;
}

export function isEdulogyDomain() {
  return window.location.hostname.includes(".edulogy.id");
}
