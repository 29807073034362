import {
  // USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOGGED_IN_BY_GOOGLE,
  USER_PROFILE_UPDATED,
} from "./type";
import userAPI from "api/user";

// export const userLoggedIn = (auth) => ({
//   type: USER_LOGGED_IN,
//   auth,
// });

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const userLoggedInByGoogle = (auth) => ({
  type: USER_LOGGED_IN_BY_GOOGLE,
  auth,
});

export const userProfileUpdated = ({ name }) => ({
  type: USER_PROFILE_UPDATED,
  name,
});

// export const login = (credentials) => {
//   return (dispatch) =>
//     userAPI.login(credentials).then((auth) => {
//       localStorage.setItem("auth", JSON.stringify(auth));
//       dispatch(userLoggedIn(auth));
//     });
// };

export const logout = (accessToken) => {
  return (dispatch) =>
    userAPI.logout(accessToken).then(() => {
      localStorage.removeItem("auth");
      dispatch(userLoggedOut());
    });
};

export const loginGoogle = (auth) => {
  return (dispatch) => {
    localStorage.setItem("auth", JSON.stringify(auth));
    dispatch(userLoggedInByGoogle(auth));
  };
};

export const updateProfile = ({ name }) => {
  return (dispatch) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        user: { ...auth.user, name },
      })
    );
    dispatch(userProfileUpdated({ name }));
  };
};
