import React from "react";
import { Switch, withRouter } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";

import Subscription from "./components/Layouts/Subscription";
import Investment from "./components/Layouts/Investment";
import Blank from "./components/Layouts/Blank";

import LoginPage from "pages/LoginPage";
import LogoutPage from "pages/LogoutPage";
import NoInternet from "components/Error/NoInternet";
const E_404 = lazyDelay(() => import("./pages/E_404"));
const LandingPage = lazyDelay(() => import("./pages/LandingPage"));
const SearchSchoolPage = lazyDelay(() =>
  import("./pages/PENDING_SearchSchoolPage")
);
const DinasListPage = lazyDelay(() => import("./pages/DinasListPage"));
const InstitutionProfilePage = lazyDelay(() =>
  import("./pages/InstitutionProfilePage")
);
const UserFormulirListPage = lazyDelay(() =>
  import("./pages/User/FormulirListPage")
);
const UserFormulirPage = lazyDelay(() => import("./pages/User/FormulirPage"));
const UserProfilePage = lazyDelay(() => import("./pages/User/ProfilePage"));

const App = ({ history }) => {
  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <React.Suspense fallback={<PageFallback />}>
      <Switch>
        <AnyRoute
          path="/"
          exact
          layout={Investment}
          component={DinasListPage}
          title="EduAdmission: Lakukan PPDB dengan Mudah"
        />
        <AnyRoute
          path="/search-school"
          exact
          layout={Investment}
          component={SearchSchoolPage}
          title="Pencarian Sekolah"
        />
        <AnyRoute
          path="/ppdb-negeri"
          exact
          layout={Investment}
          component={DinasListPage}
          title="PPDB Negeri"
        />
        <AnyRoute
          path="/institution/:id/guide"
          exact
          layout={Investment}
          component={InstitutionProfilePage}
          title="Panduan"
        />
        <AnyRoute
          path="/institution/:id/admission"
          exact
          layout={Investment}
          component={InstitutionProfilePage}
          title="Jalur Pendaftaran"
        />
        <UserRoute
          path="/institution/:id/admission/:admission/register"
          exact
          layout={Investment}
          component={InstitutionProfilePage}
          title="Daftar Formulir"
        />
        <AnyRoute
          path="/institution/:id/announcement"
          exact
          layout={Investment}
          component={InstitutionProfilePage}
          title="Pengumuman"
        />
        <AnyRoute
          path="/institution/:id/entrant"
          exact
          layout={Investment}
          component={InstitutionProfilePage}
          title="Penerimaan"
        />
        <UserRoute
          path="/formulir"
          exact
          layout={Subscription}
          component={UserFormulirListPage}
          title="Formulir Saya"
        />
        <UserRoute
          path="/formulir/:id"
          exact
          layout={Subscription}
          component={UserFormulirPage}
          title="Detail Formulir"
        />
        <UserRoute
          path="/profile"
          exact
          layout={Subscription}
          component={UserProfilePage}
          title="Akun Saya"
        />
        <UserRoute
          path="/logout"
          exact
          layout={Blank}
          component={LogoutPage}
          title="Sedang keluar..."
        />
        <GuestRoute
          path="/login"
          exact
          component={LoginPage}
          title="Masuk ke Web"
        />

        <AnyRoute component={E_404} title="Halaman Tidak Ditemukan" />
      </Switch>
    </React.Suspense>
  );
};

const AppWithRouter = withRouter(App);

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <AppWithRouter {...props} />
  </ErrorBoundary>
);
