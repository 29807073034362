import { useEffect } from "react";

const className = "modal-open";

// Problem trying to solve: if parent component is modal than we shouldn't
// remove "modal-open" from body. If so we'll get problem that parent component
// can't be scrolled.
export default function usePreventModalScrollCollision() {
  useEffect(() => {
    const isParentComponentAModal = document.body.classList.contains(className);

    if (isParentComponentAModal) return;

    document.body.classList.add(className);
    return () => {
      document.body.classList.remove(className);
    };
  }, []);
}
