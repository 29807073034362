import React from "react";
import Menu from "./Menu";
import Logo from "./Logo";
import QuickNav from "./QuickNav";
import UserInfo from "./UserInfo";
import Notif from "./Notif";
import styled from "styled-components";
import GoogleButton from "pages/LoginPage/GoogleButton";
import { connect } from "react-redux";
import EdulogyOnly from "components/EdulogyOnly";

const Container = styled.div`
  & {
    .nk-menu-link {
      font-family: Roboto, sans-serif;
      font-weight: 400;
    }
    .nk-menu-main > li.nk-menu-item > .nk-menu-link {
      font-size: 14px !important;
      color: #526484;
      letter-spacing: 0.01rem;
    }
    .nk-menu-main > li > .nk-menu-link {
      text-transform: capitalize;
    }
    .nk-header-menu {
      /* margin-left: auto; */
    }
    .nk-header-tools {
      margin-left: auto;
    }
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.state = { isMobileMenuShow: false };
  }

  toggleMobileMenu(e) {
    this.setState((state) => ({
      ...state,
      isMobileMenuShow: !state.isMobileMenuShow,
    }));
  }

  render() {
    const { isMobileMenuShow } = this.state;
    const { isAuthenticated } = this.props;

    return (
      <Container className="nk-header nk-header-fluid bg-white">
        <div className="container-xl wide-lg">
          <div className="nk-header-wrap">
            {/* Burger menu for mobile */}
            <div className="nk-menu-trigger mr-sm-2 d-lg-none">
              <a
                href="#"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="headerNav"
                onClick={this.toggleMobileMenu}
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>

            <EdulogyOnly>
              <Logo />
            </EdulogyOnly>

            <Menu
              isMobileMenuShow={isMobileMenuShow}
              onClose={this.toggleMobileMenu}
            />

            <QuickNav>
              {/* <UserInfo />
              <Notif />
              <li className="hide-mb-sm">
                <a href="#" className="nk-quick-nav-icon">
                  <em className="icon ni ni-signout"></em>
                </a>
              </li> */}
              {/* <LinkButton to="/p/login" variant="primary" size="lg" round>
                Masuk
              </LinkButton> */}
              {isAuthenticated ? (
                <>
                  <UserInfo />
                  <Notif />
                </>
              ) : (
                <GoogleButton />
              )}
            </QuickNav>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.access_token,
});

export default connect(mapStateToProps)(Header);
