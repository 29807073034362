import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const INSTITUTION_TYPES = [
  {
    id: "school",
    name: "Sekolah",
  },
  {
    id: "college",
    name: "Perguruan Tinggi",
  },
  {
    id: "course",
    name: "Bimbingan Belajar",
  },
  {
    id: "boarding_school",
    name: "Sekolah Bersama",
  },
];

const INSTITUTION_GRADES = [
  {
    id: "X",
    name: "X",
  },
  {
    id: "XI",
    name: "XI",
  },
  {
    id: "XII",
    name: "XII",
  },
];
const INSTITUTION_CATEGORIES = [
  {
    id: "private",
    name: "Swasta",
  },
  {
    id: "public",
    name: "Negeri",
  },
];

const PROVINCES = [
  {
    id: 1,
    name: "Jawa Barat",
  },
  {
    id: 2,
    name: "Jawa Tengah",
  },
];
const REGENCIES = [
  {
    id: 1,
    name: "Bandung",
  },
  {
    id: 2,
    name: "Jakarta",
  },
];
const DISTRICTS = [
  {
    id: 1,
    name: "Arcamanik",
  },
  {
    id: 2,
    name: "Panyileukan",
  },
];
const VILLAGES = [
  {
    id: 1,
    name: "Cisaranten Kulon",
  },
  {
    id: 2,
    name: "Mekar Mulya",
  },
];

const STUDENT = {
  national_student_number: "0075024558",
  name: "Alfikri Deandra",
  birth_location_name: "Jambi",
  birth_at: "2007-15-11",
  gender: "M",
  address: "Jl. Dr. Siwabessy",
  // NOTE: Backend says these props will not supported
  // country_id: "6041a2c143361e350c114353",
  // province_id: "6041a2c143361e350c114353",
  // regency_id: null,
  // district_id: null,
  // village_id: null,
  parent_name: "Beni Indra",
  institution_origin_identifier: "10504898",
  institution_origin_name: "SD NEGERI 022IV JAMBI",
};

const DOCUMENT_TYPES = [
  { id: "document_certificate_birth", name: "Akta Kelahiran" },
  { id: "document_certificate_family", name: "KK" },
  { id: "document_statement_good", name: "Surat Pernyataan Kelakukan Baik" },
  { id: "document_statement_domicile", name: "Surat Domisili" },
  { id: "document_statement_healthy", name: "Surat Sehat" },
  { id: "document_statement_unable", name: "Surat Tidak Mampu" },
  { id: "document_statement_electricity_bill", name: "Surat Rekening Listrik" },
  { id: "document_statement_graduation", name: "Surat Kelulusan" },
  { id: "document_statement_recommendation", name: "Surat Rekomendasi" },
  { id: "document_statement_responsibility_parent", name: "Surat Tanggungjawab Orangtua" },
  { id: "document_statement_assignment_parent", name: "Surat Penugasan Orangtua" },
  { id: "document_statement_registered_dtppfm", name: "Surat Terdaftar DTPPFM" },
  { id: "document_report_school", name: "Rapor Sekolah" },
  { id: "document_certificate_school", name: "Ijazah" },
  { id: "document_card_kks", name: "Kartu KKS" },
  { id: "document_card_pkh", name: "Kartu PKH" },
  { id: "document_card_kip", name: "Kartu KIP" },
  { id: "document_card_kis", name: "Kartu KIS" },
  { id: "document_card_kbpnt", name: "Kartu KBPNT" },
  { id: "document_home_photo", name: "Foto Rumah" },
  { id: "document_other", name: "Dokumen lainnya" },
];

const PARENT_RELATIONS = [
  {
    id: "father",
    name: "Ayah",
  },
];

const ACHIEVEMENT_ORGANIZER_TYPES = [
  {
    id: "kemendikbud",
    name: "KEMENDIKBUD",
  },
];

const ACHIEVEMENT_TYPES = [
  {
    id: "sport",
    name: "Olahraga",
  },
];

const ACHIEVEMENT_CATEGORIES = [
  {
    id: "individual",
    name: "Individu/Perseorangan",
  },
];

const ACHIEVEMENT_RANKS = [
  {
    id: "international",
    name: "Internasional",
  },
];

const ACHIEVEMENT_POSITIONS = [
  {
    id: "1st_winner",
    name: "Juara 1",
  },
];
export default [
  rest.get(`${BASE_URL}/v1/master/institution-type`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: INSTITUTION_TYPES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/institution-grade`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: INSTITUTION_GRADES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/institution-category`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: INSTITUTION_CATEGORIES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/province`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: PROVINCES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/regency`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: REGENCIES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/district`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: DISTRICTS,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/village`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: VILLAGES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/student`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: STUDENT,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/document-type`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: DOCUMENT_TYPES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/parent-relation`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: PARENT_RELATIONS,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/achievement-organizer`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ACHIEVEMENT_ORGANIZER_TYPES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/achievement-type`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ACHIEVEMENT_TYPES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/achievement-category`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ACHIEVEMENT_CATEGORIES,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/achievement-rank`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ACHIEVEMENT_RANKS,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/master/achievement-position`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ACHIEVEMENT_POSITIONS,
      })
    );
  }),
];
