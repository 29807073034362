import React from "react";
import ItemMenuWithoutIcon from "./ItemMenuWithoutIcon";

export default (props) => (
  <ul className="nk-menu nk-menu-sm">
    <li className="nk-menu-heading">
      <span>Pusat Bantuan</span>
    </li>
    <ItemMenuWithoutIcon link="https://edulogy.id/kebijakan-privasi/" text="Privasi" />
    <ItemMenuWithoutIcon link="https://www.youtube.com/channel/UCdOsw3TfLpCEr6xkP6Ngpjw" text="Tutorial YouTube" />
    <ItemMenuWithoutIcon link="https://go.edulogy.id/customer-care" text="Customer Care" />
  </ul>
);
