import React from "react";
import EdulogyLogo from "assets/images/logo-blue.png";
import { Link } from "react-router-dom";

export default (props) => (
  <div className="nk-header-brand">
    <Link to="/ppdb-negeri" className="logo-link">
      <img className="logo-img" src={EdulogyLogo} alt="logo" />
      {/* <span className="nio-version">Edulogy</span> */}
    </Link>
  </div>
);
