import { isEdulogyDomain } from "components/EdulogyOnly";
import { ErrorMessageReport } from "domain/Error/ErrorReport";
import env from "domain/Environment/Env";

export function getGoogleErrorMessage(googleResponse, defaultMessage) {
  if (!googleResponse.error) {
    ErrorMessageReport(
      "Unhandled google error: " + JSON.stringify(googleResponse)
    );
    return defaultMessage;
  }
  switch (googleResponse.error) {
    case "popup_closed_by_user": {
      return "Masuk dengan google dibatalkan.";
    }
    case "access_denied": {
      return "Akses ditolak";
    }
    case "immediate_failed": {
      ErrorMessageReport(
        "Google error maybe caused by setting prompt=none: " +
          JSON.stringify(googleResponse)
      );
      return "Terjadi kesalahan pengaturan";
    }
    case "idpiframe_initialization_failed": {
      return "Browser tidak mendukung.";
    }
    default: {
      ErrorMessageReport(
        "Unhandled google error: " + JSON.stringify(googleResponse)
      );
      return defaultMessage;
    }
  }
}

export function hideTawkChat() {
  if (window.Tawk_API) {
    if (typeof window.Tawk_API.hideWidget === "function") {
      window.Tawk_API.hideWidget();
    } else {
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.hideWidget();
      };
    }
  }
}

export function showTawkChat() {
  if (window.Tawk_API) {
    if (typeof window.Tawk_API.hideWidget === "function") {
      window.Tawk_API.showWidget();
    } else {
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.showWidget();
      };
    }
  }
}

export function getHomeUrl() {
  if (window.location.hostname === "ppdb.jambikota.go.id") {
    return "/institution/60bf37f076164156b0a90238/admission";
  }
  return process.env.REACT_APP_HOME_URL;
}
