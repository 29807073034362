import React from "react";
import HeadingMenu from "./HeadingMenu";
import ItemMenu from "./ItemMenu";
import DropdownMenu from "./DropdownMenu";
import { getHomeUrl } from "utils/misc";

export default (props) => (
  <ul className="nk-menu">
    <HeadingMenu text="Menu" />
    <ItemMenu icon="home-alt" link={getHomeUrl()} text="Halaman Depan" />
    <ItemMenu icon="files" link="/formulir" text="Formulir Saya" />
    <ItemMenu icon="user-circle" link="/profile" text="Akun Saya" />
  </ul>
);
