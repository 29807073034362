export const BASE_URL = process.env.REACT_APP_ENDPOINT;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_ENDPOINT;
export const SUCCESS_RESPONSE = {
  code: "200",
  status: "SUCCESS",
  message: "ok",
  data: {},
};
export const INVALID_RESPONSE = {
  code: "400",
  status: "INVALID_DATA",
  message: "invalid data",
  error: [
    {
      message: "Invalid value",
      field: "username",
    },
  ],
};
