import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export const ADMISSIONS = [
  {
    id: "5f748a35f3d8b02e44b25f05",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Mandiri Anak Prestasi",
    thumbnail:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    price: null,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "EDU OFFICE",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
    status_enroll: true,
  },
  {
    id: "5f748a35f3d8b02e44b25f06",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Mandiri Anak Biasa",
    thumbnail:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    price: 25_000,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "EDU OFFICE",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
  },
  {
    id: "5f748a35f3d8b02e44b25f07",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Mandiri untuk Luar Biasa",
    thumbnail:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    price: 10_000,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "Sekolah PKBM",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2020/06/25/logo-pkbm.png",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
  },
  {
    id: "5f748a35f3d8b02e44b25f08",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Mandiri untuk Luar Biasa",
    thumbnail: null,
    price: 50_000,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "Bright Kiddie",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
  },
  {
    id: "5f748a35f3d8b02e44b25f09",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Gelombang #1",
    thumbnail: null,
    price: 50_000,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "Bright Kiddie",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
  },
  {
    id: "5f748a35f3d8b02e44b25f11",
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    name: "Gelombang #2",
    thumbnail: null,
    price: 50_000,
    status: "active",
    max_formulirs: 100,
    total_formulirs: 55,
    total_capacities: 200,
    institution: {
      id: "45",
      name: "Bright Kiddie",
      logo:
        "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg",
    },
    start_at: "2021-02-22T17:00:00.000Z",
    finish_at: "2021-02-22T17:00:00.000Z",
    created_at: "2021-02-22T17:00:00.000Z",
    updated_at: "2021-02-22T17:00:00.000Z",
  },
];

export const ADMISSION = {
  id: "5f748a35f3d8b02e44b25f05",
  uuid: "123e4567-e89b-12d3-a456-426614174000", //uuidv4
  name: "PPDB Jalur Zonasi", //max: 100
  description: null, //null or html string, max: 65000
  thumbnail: null, //null or string, max: 500
  price: null, //null or integer, min: 10000, max: 10000000
  status: "active", //active, inactive
  max_formulirs: null, //null or integer, min: 1, max: 5000
  total_formulirs: 55, //default 0
  total_capacities: null, //null or integer, min: 1, max: 5000
  show_max_formulir: false,
  show_total_formulir: false,
  show_approval_at: "2020-06-09 08:29:00", //null or date
  institution: {
    id: "45",
    name: "EDU OFFICE",
    logo: "edulogy.jpg",
  },
  total_schools: 2,
  schools: [
    {
      id: 1,
      name: "SMA 24 Lembang",
      logo: "",
      latitude: "-6.8059247",
      longitude: "107.4835852",
    },
    {
      id: 2,
      name: "SMA 5 ",
      logo: "",
      latitude: "-6.1786692",
      longitude: "106.9179834",
    },
  ],
  formulir_settings: [
    {
      field: "citizen_national_identifier", //nik
      show: true,
      required: true,
    },
    {
      field: "name",
      show: true,
      required: true,
    },
    {
      field: "gender",
      show: true,
      required: true,
    },
    {
      field: "birth_place",
      show: true,
      required: true,
    },
    {
      field: "birth_at",
      show: true,
      required: true,
    },
    {
      field: "phone",
      show: true,
      required: false,
    },
    {
      field: "email",
      show: true,
      required: false,
    },
    {
      field: "photo",
      show: true,
      required: false,
    },
    {
      field: "achievement",
      show: true,
      required: false,
    },
    {
      field: "address",
      show: true,
      required: false,
      zonation: {
        //object or null
        village_ids: [3273201005, 1571081001, 1571031007, 3273241001],
      },
    },
    {
      field: "age",
      show: true,
      required: false,
      range: {
        //object or null
        minimum_total_days: 5475, //null or integer, min: 1
        maximum_total_days: 6570, //null or integer, min: 1
        date: "2020-05-31",
      },
    },
    {
      field: "school_national_identifier", //nisn
      show: true,
      required: false,
    },
    {
      field: "institution_origin_name",
      show: true,
      required: false,
    },
    {
      field: "institution_origin_identifier", //npsn
      show: true,
      required: false,
    },
    {
      field: "institution_origin_graduation_year", //tahun lulus
      show: true,
      required: false,
    },
    {
      field: "parent_name",
      show: true,
      required: false,
    },
    {
      field: "parent_gender",
      show: true,
      required: false,
    },
    {
      field: "parent_phone",
      show: true,
      required: false,
    },
    {
      field: "parent_email",
      show: true,
      required: false,
    },
    {
      field: "document_certificate_birth", //akta
      show: true,
      required: false,
    },
    {
      field: "document_certificate_family", //kk
      show: true,
      required: false,
    },
    {
      field: "document_statement_good", //skck
      show: true,
      required: false,
    },
    {
      field: "document_statement_domicile", //tinggal
      show: true,
      required: false,
    },
    {
      field: "document_statement_healthy", //sehat
      show: true,
      required: false,
    },
    {
      field: "document_statement_unable", //tidak mampu
      show: true,
      required: false,
    },
    {
      field: "document_statement_electricity_bill", //rekening listrik
      show: true,
      required: false,
    },
    {
      field: "document_statement_graduation", //skl
      show: true,
      required: false,
    },
    {
      field: "document_statement_recommendation", //rekomendasi
      show: true,
      required: false,
    },
    {
      field: "document_statement_responsibility_parent", //tanggung jawab orangtua wali
      show: true,
      required: false,
    },
    {
      field: "document_statement_assignment_parent", //penugasan orangtua/wali
      show: true,
      required: false,
    },
    {
      field: "document_statement_registered_dtppfm", //Data Terpadu Program Penanganan Fakir Miskin
      show: true,
      required: false,
    },
    {
      field: "document_report_school", //rapor
      show: true,
      required: false,
    },
    {
      field: "document_certificate_school", //ijazah
      show: true,
      required: false,
    },
    {
      field: "document_card_kks", //
      show: true,
      required: false,
    },
    {
      field: "document_card_pkh",
      show: true,
      required: false,
    },
    {
      field: "document_card_kip",
      show: true,
      required: false,
    },
    {
      field: "document_card_kis",
      show: true,
      required: false,
    },
    {
      field: "document_card_kbpnt",
      show: true,
      required: false,
    },
    {
      field: "document_home_photo",
      show: true,
      required: false,
    },
    {
      field: "document_other",
      show: true,
      required: false,
    },
  ],
  institution_id: "6041a2c143361e350c114353",
  start_at: "2020-06-09 08:29:00",
  finish_at: "2020-06-09 08:29:00",
  created_at: "2020-06-09 08:29:00",
  updated_at: "2020-06-09 08:29:00",
};

const STATISTIC = {
  total_registrants: 1000,
  total_capacities: 1000,
  total_unprocess: 1000,
  total_rejecteds: 1000,
  total_approvals: 1000,
  nearest_distances: 1000,
  fartest_distances: 1000,
  average_distances: 1000,
};

export const EDULOGY_STATISTIC = {
  total_institutions: 1000,
  total_parents: 1000,
  total_students: 1000,
  total_admissions: 1000,
  total_available_formulirs: 1000,
  total_register_formulirs: 1000,
};

export default [
  rest.get(`${BASE_URL}/v1/admission`, (req, res, ctx) => {
    const { limit = 99999, offset = 0 } = req.params;
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ADMISSIONS.slice(offset, limit),
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/admission/:id`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ADMISSION,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/admission-approval-statistic`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: STATISTIC,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/admission-statistic`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: EDULOGY_STATISTIC,
      })
    );
  }),
];
