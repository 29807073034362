import { rest } from "msw";
import { STORAGE_URL, SUCCESS_RESPONSE } from "./variables";

const FILE = {
  id: 1,
  name: "your-image.jpg",
  size: 35300,
  url: "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg"
};

export default [
  rest.post(`${STORAGE_URL}/v1/file`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: [FILE],
      })
    );
  }),
];
