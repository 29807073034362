import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Card from "components/atoms/Card/Card";
import Button from "components/atoms/Button/Button";
import Inline from "components/atoms/List/Inline";
import userAPI from "api/user";
import useBool from "hooks/useBool";
import { ErrorReport } from "domain/Error/ErrorReport";
import { firebaseApp } from "configuredFirebase";
import { useSelector } from "react-redux";

export default function AskPushNotifPermission() {
  const [isShow, show, close] = useBool(!isPushNotifAsked());
  const [isProcessing, setAsProcessing, setAsProcessed] = useBool(false);

  async function handleSubscribe() {
    setAsProcessing();
    try {
      if (!isPushNotificationSupported()) return;

      await navigator.serviceWorker.register("/firebase-messaging-sw.js");

      const messaging = firebaseApp.messaging();
      const currentToken = await messaging.getToken({
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      const isNotPermitted = !Boolean(currentToken);
      if (isNotPermitted) {
        markPushNotifAsDisabled();
        return;
      }

      await userAPI.subscribePushNotif(currentToken);
      messaging.onMessage(({ notification }) => {
        new Notification(notification.title, {
          body: notification.body,
          // icon: "",
        });
        // notification.onclick = function (event) {
        //   event.preventDefault(); // prevent the browser from focusing the Notification's tab
        //   window.open("http://www.mozilla.org", "_blank");
        // };
      });
      markPushNotifAsEnabled();
      close();
    } catch (err) {
      ErrorReport(err);
    }
    setAsProcessed();
  }

  const isAuthenticated = useSelector((state) => !!state.auth.access_token);
  if (!isAuthenticated) return null;
  if (!isShow) return null;

  return (
    <Container>
      <Card isBordered className="bg-primary-dim border-primary">
        <Card.Inner className="p-2">
          <p>
            Ingin mendapatkan notifikasi di perangkat ini jika formulir berubah
            status atau ada pengumuman baru dari dinas ?
          </p>
          <Inline gap={3}>
            <Button
              size="sm"
              variant="primary"
              onClick={handleSubscribe}
              disabled={isProcessing}
            >
              Aktifkan Pemberitahuan
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                markPushNotifAsDisabled();
                close();
              }}
              disabled={isProcessing}
            >
              Tidak perlu
            </Button>
          </Inline>
        </Card.Inner>
      </Card>
    </Container>
  );
}

export const PUSH_NOTIF_LOCALSTORAGE_NAME = "is_push_notif_enabled";
export const IS_PUSH_NOTIF_ENABLED = "true";
export const IS_PUSH_NOTIF_DISABLED = "false";
export function isPushNotifAsked() {
  return Boolean(window.localStorage.getItem(PUSH_NOTIF_LOCALSTORAGE_NAME));
}
export function markPushNotifAsNotAsked() {
  window.localStorage.removeItem(PUSH_NOTIF_LOCALSTORAGE_NAME);
}
function markPushNotifAsEnabled() {
  window.localStorage.setItem(
    PUSH_NOTIF_LOCALSTORAGE_NAME,
    IS_PUSH_NOTIF_ENABLED
  );
}
function markPushNotifAsDisabled() {
  window.localStorage.setItem(
    PUSH_NOTIF_LOCALSTORAGE_NAME,
    IS_PUSH_NOTIF_DISABLED
  );
}
export function isPushNotifEnabled() {
  return (
    window.localStorage.getItem(PUSH_NOTIF_LOCALSTORAGE_NAME) ===
    IS_PUSH_NOTIF_ENABLED
  );
}

function isPushNotificationSupported() {
  return (
    "serviceWorker" in navigator &&
    "PushManager" in window &&
    "Notification" in window
  );
}

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 10px;
  width: 100%;
  z-index: 9999;
  padding-left: 3px;
  padding-right: 3px;

  & > div {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 3px;
    box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2),
      0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
  }
`;
