export const UPDATED = "NEW_FORMULIR/UPDATED";

export const MASS_UPDATED = "NEW_FORMULIR/MASS_UPDATED";

export const ADDRESS_UPDATED = "NEW_FORMULIR/ADDRESS_UPDATED";

export const CLEARED = "NEW_FORMULIR/CLEARED";

export const DOCUMENT_ADDED = "NEW_FORMULIR/DOCUMENT/ADDED";

export const DOCUMENT_DELETED = "NEW_FORMULIR/DOCUMENT/DELETED";

export const PARENT_ADDED = "NEW_FORMULIR/PARENT/ADDED";

export const PARENT_UPDATED = "NEW_FORMULIR/PARENT/UPDATED";

export const PARENT_DELETED = "NEW_FORMULIR/PARENT/DELETED";

export const ACHIEVEMENT_ADDED = "NEW_FORMULIR/ACHIEVEMENT/ADDED";

export const ACHIEVEMENT_UPDATED = "NEW_FORMULIR/ACHIEVEMENT/UPDATED";

export const ACHIEVEMENT_DELETED = "NEW_FORMULIR/ACHIEVEMENT/DELETED";
