import React from "react";
import PropTypes from "prop-types";
import BlueLogo from "assets/images/logo-blue.png";
import { Link } from "react-router-dom";
import GoogleButton from "./GoogleButton";
import EdulogyOnly from "components/EdulogyOnly";

class Login extends React.Component {
  render() {
    return (
      <div className="nk-app-root nk-body bg-white npc-general pg-auth">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <EdulogyOnly>
                  <div className="brand-logo pb-3 pl-4">
                    <Link to="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={BlueLogo}
                        style={
                          {
                            opacity: 1,
                          } /* hapus ini kalau ingin support dark theme */
                        }
                        alt="logo"
                      />
                      {/* <img
                      className="logo-dark logo-img logo-img-lg"
                      src={WhiteLogo}
                      srcset="./logo-dark2x.png 2x"
                      alt="logo-dark"
                    /> */}
                    </Link>
                  </div>
                </EdulogyOnly>
                <div className="card">
                  <div className="card-inner card-inner-lg pt-1">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Login diperlukan</h4>
                        <div className="nk-block-des">
                          <p>Halaman yang Anda kunjungi meminta Anda login.</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <GoogleButton />
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      {/* Gapai mimpimu. */}
                    </div>
                    {/* <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <ul className="nav justify-center gx-4">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Facebook
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Google
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-6 order-lg-last">
                      <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            target="_blank"
                            href="https://edulogy.id/kebijakan-privasi/"
                          >
                            Kebijakan Privasi
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            target="_blank"
                            href="https://go.edulogy.id/customer-care"
                          >
                            Customer Care
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-soft">
                          <EdulogyOnly>&copy; 2020 Edulogy. All Rights Reserved.</EdulogyOnly>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
