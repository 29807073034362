import React from "react";
import Logo from "./Logo";
import QuickNav from "./QuickNav";
import ErrorBoundary from "components/Error/ErrorBoundary";
import {BurgerMenu} from "./QuickNav/index"
import EdulogyOnly from "components/EdulogyOnly";

const Header = (props) => (
  <div className="nk-header nk-header-fixed is-light">
    <div className="container-lg wide-xl">
      <div className="nk-header-wrap">
        <ErrorBoundary fallback={<span></span>}>
        <BurgerMenu />

          <EdulogyOnly>
          <Logo />
          </EdulogyOnly>

          <QuickNav />
        </ErrorBoundary>
      </div>
    </div>
  </div>
);

export default Header;
