import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUSH_NOTIF_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_PUSH_NOTIF_FIREBASE_PROJECT_ID,
  messagingSenderId:
  process.env.REACT_APP_PUSH_NOTIF_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_PUSH_NOTIF_FIREBASE_APP_ID,
  // authDomain: process.env.REACT_APP_PUSH_NOTIF_FIREBASE_AUTH_DOMAIN,
  // storageBucket: process.env.REACT_APP_PUSH_NOTIF_FIREBASE_STORAGE_BUCKET,
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

export { firebaseApp };
