import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Col = ({ size, children, className = "", ...props }) => {
  if (typeof size === "number" || typeof size === "string") {
    size = {
      col: +size,
      sm: +size,
      md: +size,
      lg: +size,
      xl: +size,
    };
  }

  return (
    <div
      className={cx(
        size.sm && `col-${size.sm}`, // on dashlite template "sm" is min-width 576px, so below that we need "col" class
        size.sm && `col-sm-${size.sm}`,
        size.md && `col-md-${size.md}`,
        size.lg && `col-lg-${size.lg}`,
        size.xl && `col-xl-${size.xl}`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Col;
