import React from "react";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import MenuItemWithIcon from "./MenuItemWithIcon";
import MenuDropdown from "./MenuDropdown";
import EdulogyLogo from "assets/images/logo-blue.png";
import { getHomeUrl } from "utils/misc";

const MOBILE_SIZE_MINIMUM = 992; // in pixel

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { screenWidth } = this.state;
    const { isMobileMenuShow, onClose } = this.props;
    return (
      <div
        className={
          "nk-header-menu" +
          (isMobileMenuShow ? " nk-sidebar-active" : "") +
          (screenWidth < MOBILE_SIZE_MINIMUM ? " mobile-menu" : "")
        }
        data-content="headerNav"
      >
        {/* Show brand logo in mobile only */}
        <div className="nk-header-mobile">
          <div className="nk-header-brand">
            <img className="logo-img" src={EdulogyLogo} alt="logo" />
          </div>
          <div className="nk-menu-trigger mr-n2">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="headerNav"
              onClick={onClose}
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>

        {/* Show menu (for both mobile and web) */}
        <ul className="nk-menu nk-menu-main">
          {/* <MenuItem link="/" text="Beranda" /> */}
          <MenuItem link={getHomeUrl()} text="Beranda" />
          {/* <MenuItem link="html/invest/index.html" text="Panduan" /> */}
          {/* <MenuItem link="html/invest/index.html" text="PPDB Negeri" /> */}
          {/* <MenuItem link="html/invest/index.html" text="Cek Kelulusan" /> */}
          {/* <MenuItem link="html/invest/index.html" text="Cari Sekolah" /> */}
          {/* <MenuItem link="html/invest/index.html" text="Promo" /> */}
          {/* <MenuItem
            link="https://sekolah.edulogy.id/register"
            text="Aktivasi"
          /> */}
          <MenuItem
            link="/formulir"
            text={<strong>Formulir Saya</strong>}
          />
        </ul>
      </div>
    );
  }
}

Menu.propTypes = {};

export default Menu;
