import axios from "configuredAxios";

export default {
  get: ({ limit, offset }) => {
    return axios
      .get("v1/my/notification", {
        params: {
          limit,
          offset,
        },
      })
      .then((res) => res.data.data);
  },
  markAsRead: (id) => {
    return axios.post(`v1/my/notification/${id}/read`);
  },
  markAllAsRead: () => {
    return axios.post(`v1/my/notification/read-all`);
  },
};
