import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export const MY_FORMULIRS = [
  {
    id: "5f748a35f3d8b02e44b25f05",
    uuid: "123e4567-e89b-12d3-a456-426614174000", //uuidv4
    code: "16143128824940192",
    status_verification: "unprocess", //[unprocess, invalid, valid]
    status_approval: "unapproved", //[unprocess, unapproved, approved]
    status_registration: "unregistered",
    admission: {
      name: "Zonasi",
    },
    institution: {
      id: "6041a2c143361e350c114353",
      name: "Dinas Kota Bandung",
      logo: "https://image.png",
    },
    schools: [
      {
        id: "6041a2c143361e350c114353",
        name: "EduOffice",
        logo: "https://image.png",
        status_approval: "unapproved", //[unprocess, unapproved, approved]
        formated_status_approval: "Tidak lulus", //[unprocess, unapproved, approved]
        total_distances: 2322, //meter
      },
    ],
    identity: {
      name: "Raka Suryaardi Widjaja",
      birth_at: "1990-01-01",
    },
    total_achievement_scores: 150, //kumulatif
    total_notes: 1,
    created_at: "2021-02-22T17:00:00.000Z",
  },
  {
    id: "5f748a35f3d8b02e44b25f05",
    uuid: "123e4567-e89b-12d3-a456-426614174000", //uuidv4
    code: "16143128824940192",
    status_verification: "unprocess", //[unprocess, invalid, valid]
    status_approval: "unapproved", //[unprocess, unapproved, approved]
    status_registration: "unregistered",
    admission: {
      name: "Zonasi",
    },
    institution: {
      id: "6041a2c143361e350c114353",
      name: "Dinas Kota Bandung",
      logo: "https://image.png",
    },
    schools: [
      {
        id: "6041a2c143361e350c114353",
        name: "EduOffice",
        logo: "https://image.png",
        status_approval: "unapproved", //[unprocess, unapproved, approved]
        formated_status_approval: "Tidak lulus", //[unprocess, unapproved, approved]
        total_distances: 2322, //meter
      },
    ],
    identity: {
      name: "Raka Suryaardi Widjaja",
      birth_at: "1990-01-01",
    },
    total_achievement_scores: 150, //kumulatif
    total_notes: 1,
    created_at: "2021-02-22T17:00:00.000Z",
  },
];

export const MY_FORMULIR = {
  id: 1,
  uuid: "123e4567-e89b-12d3-a456-426614174000",
  code: "16143128824940192",
  token: "hie758",
  status_verification: "unprocess", //[unprocess, invalid, valid]
  formated_status_verification: "Belum diproses", //[unprocess, invalid, valid]
  status_approval: "unapproved", //[unprocess, unapproved, approved]
  formated_status_approval: "Tidak diterima", //[unprocess, unapproved, approved]
  status_payment: "free", //[free, waiting_transaction, waiting_payment, failed, success, canceled]
  formated_status_payment: "Gratis", //[free, waiting_transaction, waiting_payment, failed, success, canceled]
  graduation_year: "2015",
  admission: {
    id: 1,
    name: "Zonasi",
  },
  institution: {
    id: 1,
    name: "SMAN 24 Bandung",
  },
  approved_in: null,
  school_ids: [1, 2],
  schools: [
    { $oid: "5f748a35f3d8b02e44b25f05" },
    { $oid: "5f748a35f3d8b02e44b25f05" },
  ],
  notes: [
    {
      id: 1,
      message: "Mohon lengkapi data KTP orangtuanya yaa..",
      user_id: "h98f30dwpokghr9dpo",
      user: {
        $oid: "h98f30dwpokghr9dpo",
        name: "Yayan Heryanto",
      },
      created_at: "2021-02-22T17:00:00.000Z",
      updated_at: "2021-02-22T17:00:00.000Z",
    },
    {
      id: 2,
      message: "Mohon lengkapi data KTP orangtuanya yaa..",
      user_id: "h98f30dwpokghr9dpo",
      user: {
        $oid: "h98f30dwpokghr9dpo",
        name: "Yayan Heryanto",
      },
      created_at: "2021-02-22T17:00:00.000Z",
      updated_at: "2021-02-22T17:00:00.000Z",
    },
  ],
  identity: {
    citizen_national_identifier: "10115137", //NIK
    school_national_identifier: "1234567890", //NISN
    graduation_year: "2015",
    name: "Raka Suryaardi Widjaja",
    gender: "L",
    birth_regency_name: "Bandung",
    birth_at: "1990-01-01",
    phone: "082289007883",
    email: "raka@edulogy.id",
    photo: {
      id: "604cb771937ed8325c720ed7",
      url:
        "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    },
  },
  achievements: [
    {
      name: "Juara 1 Lomba Makan Kerupuk",
      description: null,
      type: "sport", // [sport, art, academic, religion, other]
      category: "team", // [individual, team]
      rank: "international", //[international, asia, asean, national, province, regency]
      position: "1st_winner", //['1st_winner','2nd_winner','3rd_winner','1st_runnerup','2nd_runnerup','3rd_runnerup','1_juz','2_juz','3_juz','other']
      organizer_type: "kemenpora", //['other','kemendikbud','kemenag','kemenpora']
      organizer_name: null, //required if type: other
      status_verification: "unprocess", //[unprocess, invalid, valid]
      score: "120.50", //null
      document_ids: ["604cb771937ed8325c720ed7", "604cb771937ed8325c720ed7"],
      documents: [
        { $oid: "604cb771937ed8325c720ed7" },
        { $oid: "604cb771937ed8325c720ed7" },
      ],
    },
  ],
  address: {
    id: "604cb771937ed8325c720ed7",
    country_id: 1,
    province_id: 2,
    regency_id: 1,
    district_id: 2,
    village_id: 1,
    postal_code: "401684",
    latitude: "-85.398571",
    longitude: "-64.151781",
    rt: "08",
    rw: "03",
    street: "Jl. Soekarno Hatta No.1",
  },
  documents: [
    {
      attachment: {
        $oid: "604cb771937ed8325c720ed7",
      },
      id: 1,
      name: "Foto Formalku.jpg",
      size: 25000,
      url: "https://upload.wikimedia.org/wikipedia/commons/3/31/MM_QRcode.png",
      document_type: "formal_photo", //certificate_birth, certificate_family, statement_good, statement_domicile, statement_healthy, statement_unable, statement_electricity_bill, statement_graduation, statement_recommendation, statement_responsibility_parent, statement_assignment_parent, statement_registered_dtppfm, report_school, certificate_school, card_kks, card_pkh, card_kip, card_kis, card_kbpnt, home_photo, other
      formated_document_type: "Foto Formal",
    },
  ],
  parents: [
    {
      type: "vice", //mother, father, vice
      name: "Meow Meow",
      gender: "M",
      phone: "0872828282",
      email: "parent@edulogy.id",
    },
  ],
  institution_origin: {
    name: "SDN 7 Bandung",
    identifier: "7584748347", //npsn
  },
  user_id: "604cb771937ed8325c720ed7",
  user: {
    $oid: "604cb771937ed8325c720ed7",
  },
  qrcode: "https://upload.wikimedia.org/wikipedia/commons/3/31/MM_QRcode.png",
  created_at: "2020-06-09 08:29:00",
  updated_at: "2020-06-09 08:29:00",
  deleted_at: null,
};

export default [
  rest.get(`${BASE_URL}/v1/my/formulir`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: MY_FORMULIRS,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/my/formulir/:id`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: MY_FORMULIR,
      })
    );
  }),
  rest.put(`${BASE_URL}/v1/formulir-identity`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
      })
    );
  }),
  rest.put(`${BASE_URL}/v1/formulir-address`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
      })
    );
  }),
];
