import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";

const PageFallback = () => (
  <ModalPortal>
    <div
      style={{
        position: "fixed",
        top: "40%",
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div className="d-flex justify-content-center">
        <h5>Memuat Halaman</h5>
      </div>
    </div>
  </ModalPortal>
);

PageFallback.propTypes = {};

export default PageFallback;
