import React from "react";
import PropTypes from "prop-types";
import NoInternet from "components/Error/NoInternet";
import RateLimitError from "components/Error/RateLimitError";
import PageError from "components/Error/PageError";
import ModalPortal from "components/ModalPortal";
import { connect } from "react-redux";
import env from "domain/Environment/Env";
import { ErrorReport } from "domain/Error/ErrorReport";
import { showErrorPopup } from "redux/page/action";
import EndpointNotFoundError from "./Error/EndpointNotFoundError";
import ForbiddenError from "./Error/ForbiddenError";
import PageLoading from "./Loading/PageLoading";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (env.isDev() || env.isProd()) {
      ErrorReport(error, info.componentStack);
    }
    this.props.showErrorPopup();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    setTimeout(() => window.scrollTo(0, 0), 1000);
  }

  render() {
    const {
      isErrorPopupShow,
      isError404PopupShow,
      isConnectionErrorPopupShow,
      isForbiddenErrorPopupShow,
      isRateLimitErrorPopupShow,
    } = this.props;

    return (
      <>
        {isConnectionErrorPopupShow && (
          <ModalPortal>
            <NoInternet />
          </ModalPortal>
        )}
        {isRateLimitErrorPopupShow && <RateLimitError />}

        {isErrorPopupShow && <PageError />}
        {isError404PopupShow && <EndpointNotFoundError />}
        {isForbiddenErrorPopupShow && <ForbiddenError />}

        {this.props.loader.isShow && <PageLoading isLoading={true} variant="ellipsis" />}

        {!this.state.hasError && this.props.children}
      </>
    );
  }
}

Page.propTypes = {};

const mapStateToProps = (state) => ({
  loader: state.page.loader,
  isConnectionErrorPopupShow: state.page.isConnectionErrorPopupShow,
  isErrorPopupShow: state.page.isErrorPopupShow,
  isError404PopupShow: state.page.isError404PopupShow,
  isForbiddenErrorPopupShow: state.page.forbiddenErrorPopup.isShow,
  isRateLimitErrorPopupShow: state.page.isRateLimitErrorPopupShow,
});

export default connect(mapStateToProps, { showErrorPopup })(Page);
