import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import cx from "classnames";

const Investment = (props) => (
  <div className="nk-app-root nk-body npc-invest bg-white">
    <div className="nk-wrap">
      <Header />
      {props.children}
      <Footer />
    </div>
  </div>
);

Investment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

Investment.Container = function Container({
  children,
  className = "",
  paddingTop = 25,
  style,
  ...restProps
}) {
  return (
    <div
      className={cx(
        "nk-content",
        "nk-content-lg",
        "nk-content-fluid",
        className
      )}
      style={{ paddingTop, ...style }}
      {...restProps}
    >
      <div className="container-xl wide-lg">
        <div className="nk-content-inner">
          <div className="nk-content-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
