import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Row = ({ children, className = "", ...props }) => (
  <div className={cx("row", className)} {...props}>
    {children}
  </div>
);

export default Row;
