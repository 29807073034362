import React from 'react';
import PropTypes from 'prop-types';

class MenuDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { isShow: false };
    }

    toggle(e) {
        this.setState({ isShow: !this.state.isShow });
    }

    render() {
        const { isShow } = this.state;
        const { text, children } = this.props;

        return (
            <li onClick={this.toggle}
                className={"nk-menu-item has-sub" + (isShow ? " active" : "") }>
                <a href="#" className="nk-menu-link nk-menu-toggle">
                    <span className="nk-menu-text">{text}</span>
                </a>
                <ul className="nk-menu-sub">
                    {children}
                </ul>
            </li>
        );
    }
}

MenuDropdown.propTypes = {

}

export default MenuDropdown;