import React from "react";
import Notif from "./Notif";
import UserInfo from "./UserInfo";
import { MobileMenuContext } from "components/Layouts/Subscription";
import { Link } from "react-router-dom";
import ErrorBoundary from "components/Error/ErrorBoundary";

export const BurgerMenu = (props) => {
  const mobileMenu = React.useContext(MobileMenuContext);
  return (
    <div className="nk-menu-trigger mr-sm-2 d-lg-none">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          mobileMenu.toggle();
        }}
        className="toggle nk-quick-nav-icon mr-1"
        data-target="sideNav"
      >
        <em className="icon ni ni-menu"></em>
      </a>
    </div>
  );
};

const QuickNav = (props) => (
  <div className="nk-header-tools">
    <ErrorBoundary
      fallback={
        <Link to="/logout" className="btn btn-primary ml-2">
          <em className="icon ni ni-signout"></em>
          <span>Keluar</span>
        </Link>
      }
    >
      <ul className="nk-quick-nav">
        <UserInfo />
        <Notif />
        {/* <BurgerMenu /> */}
      </ul>
    </ErrorBoundary>
  </div>
);

export default QuickNav;
