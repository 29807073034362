const ERROR_MESSAGE_BLACKLIST_KEYWORD = [
  "ResizeObserver",
  "getReadMode",
  "Network Error",
  "Request failed with status code 400",
  "on 0,0: Script error.",
  "CKEditorError",
];

export const isErrorMessageOnBlacklist = (errorMessage) => {
  if (typeof errorMessage !== "string") return false;

  for (const keyword of ERROR_MESSAGE_BLACKLIST_KEYWORD) {
    if (errorMessage.search(keyword) !== -1) return true;
  }
  return false;
};
