import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const AREA_DETECTION = {
  country: {
    id: "5f748a35f3d8b02e44b25f05",
    name: "Indonesia",
    latitude: "-0.78927500",
    longitude: "113.92132570",
    total_distances: 973463,
  },
  province: {
    id: 1,
    name: "Jawa Barat",
    latitude: "-7.09091100",
    longitude: "107.66888700",
    total_distances: 17057,
  },
  regency: {
    id: 1,
    name: "Kota Bandung",
    latitude: "-6.91746390",
    longitude: "107.61912280",
    total_distances: 6165,
  },
  district: {
    id: 2,
    name: "Panyileukan",
    latitude: "-6.95394560",
    longitude: "107.67776690",
    total_distances: 1965,
  },
  village: {
    id: 2,
    name: "Mekar Mulya",
    latitude: "-6.95394560",
    longitude: "107.67776690",
    total_distances: 1965,
  },
  postal_code: "1265",
};

const DISTANCE_CALCULATION = {
  total_distances: 2406,
  formated_total_distances: "2.406 Meter",
};

export default [
  rest.post(`${BASE_URL}/v1/area-detection`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: AREA_DETECTION,
      })
    );
  }),
  rest.post(`${BASE_URL}/v1/distance-calculation`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: DISTANCE_CALCULATION,
      })
    );
  }),
];
