import axios from "configuredAxios";

export default {
  logout: (access_token) => {
    return axios.post("/v1/auth/logout", { access_token });
  },
  loginGoogle: ({ google_token }) => {
    return axios
      .post("/v1/auth/google-signin", { google_token })
      .then((res) => res.data.data);
  },
  updateProfile: ({ name, email, phone }) => {
    return axios.post(`v1/profile`, {
      name,
      email,
      phone,
    });
  },
  getProfile: () => {
    return axios.get(`v1/profile`).then((res) => res.data.data);
  },
  subscribePushNotif: (token) => {
    return axios.post(`v1/fcm-token`, { token });
  },
  unsubscribePushNotif: () => {
    return axios.post(`v1/fcm-token`, { token: null });
  },
  generateSession: () => {
    return axios.get(`v1/session`).then((res) => res.data.data);
  },
  validateSession: (token) => {
    return axios
      .get(`v1/session/${token}`, { __bypass: [404] })
      .then((res) => res.data.data);
  },
};
