import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Footer from "./Footer";

export const MobileMenuContext = React.createContext({});

const Subscription = (props) => {
  const [isMobileMenuShow, setIsMobileMenuShow] = React.useState(false);

  return (
    <MobileMenuContext.Provider
      value={{
        isShow: isMobileMenuShow,
        toggle: () => setIsMobileMenuShow(!isMobileMenuShow),
        close: () => setIsMobileMenuShow(false),
      }}
    >
      <div className="nk-app-root nk-body npc-subscription has-aside ui-clean no-touch nk-nio-theme">
        <div className="nk-main">
          <div className="nk-wrap">
            <Header />

            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  <SideMenu />

                  <div className="nk-content-body">
                    <div className="nk-content-wrap">
                      {props.children} {/* CONTENT WILL BE PLACED HERE  */}
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MobileMenuContext.Provider>
  );
};

Subscription.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default Subscription;
