import { useState } from "react";

export default function useBool(initialState = false) {
  const [state, setState] = useState(initialState);

  function setTrue() {
    setState(true);
  }
  function setFalse() {
    setState(false);
  }

  return [state, setTrue, setFalse];
}
