import EdulogyOnly from "components/EdulogyOnly";
import React from "react";

export default (props) => (
  <div className="nk-footer">
    <div className="container wide-xl">
      <div className="nk-footer-wrap g-2">
        <div className="nk-footer-copyright">
          <EdulogyOnly>&copy; 2020 Edulogy.</EdulogyOnly>
        </div>
        <div className="nk-footer-links">
          <ul className="nav nav-sm">
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href="https://edulogy.id/kebijakan-privasi/"
              >
                Kebijakan Privasi
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href="https://go.edulogy.id/customer-care"
              >
                Customer Care
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
