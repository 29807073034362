import {
  // USER_LOGGED_IN,
  // USER_LOGGED_OUT,
  USER_LOGGED_IN_BY_GOOGLE,
  USER_PROFILE_UPDATED,
} from "./type";

function auth(state = {}, action = {}) {
  switch (action.type) {
    // case USER_LOGGED_IN:
    //   return action.auth;
    // case USER_LOGGED_OUT:
    //   return {};
    case USER_LOGGED_IN_BY_GOOGLE:
      return action.auth;
    // case USER_GOOGLE_ATTACHED:
    //   return {
    //     ...state,
    //     student: { ...state.student, has_google_account: true },
    //   };
    // case USER_GOOGLE_DETACHED:
    //   return {
    //     ...state,
    //     student: { ...state.student, has_google_account: false },
    //   };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.name,
        },
      };
    default:
      return state;
  }
}

export default auth;
