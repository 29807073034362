import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const PROFILE = {
  id: "6041a2c143361e350c114353",
  email: "raka.suryaardi@gmail.com",
  name: "Raka Suryaardi Widjaja",
  gender: "M",
  formated_gender: "Laki-laki",
  photo:
    "https://dewantara.storage.dev.edulogy.id/file/student/photo/2020/12/01606877-dodol-garut.jpg",
  has_google_account: true,
  has_password: true,
  phone: "0888888888",
};

export default [
  rest.get(`${BASE_URL}/v1/profile`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: PROFILE,
      })
    );
  }),
];
