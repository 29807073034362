import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { hideForbiddenErrorPopup } from "redux/page/action";
import usePreventModalScrollCollision from "hooks/usePreventModalScrollCollision";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export default function ForbiddenError() {
  usePreventModalScrollCollision();
  const dispatch = useDispatch();
  const message = useSelector(
    (state) => state.page.forbiddenErrorPopup.message
  );
  return (
    <ModalPortal>
      <Container>
        <div className="nk-content ">
          <div className="nk-block nk-block-middle wide-xs mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">
                <em className="ni ni-lock-alt-fill"></em>
              </h1>
              <h3 className="nk-error-title">Tindakan Tak Diizinkan :)</h3>
              <p className="nk-error-text">
                Mungkin data yang kamu akses bukan milikmu. Atau data tersebut
                sedang dilindungi dari tindakan yang merusak.
              </p>
              {message && (
                <mark className="mb-2 h5 d-block">{message}</mark>
              )}
              <button
                type="button"
                onClick={() => dispatch(hideForbiddenErrorPopup())}
                className="btn btn-lg btn-primary mt-2"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </Container>
    </ModalPortal>
  );
}
