import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const NOTIFICATIONS = [
  {
    id: 1,
    content: "Admin Dinas mengirimi Anda catatan",
    unread: true,
    created_at: "2021-02-22T17:00:00.000Z",
  },
];

export default [
  rest.get(`${BASE_URL}/v1/my/notification`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: NOTIFICATIONS,
      })
    );
  }),
  rest.post(`${BASE_URL}/v1/my/notification/:id/read`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
      })
    );
  }),
  rest.post(`${BASE_URL}/v1/my/notification/read-all`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
      })
    );
  }),
];
