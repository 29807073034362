import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import ModalPortal from "components/ModalPortal";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const Backdrop = styled.div`
  opacity: 0.5;
  transition: opacity 0.15s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #364a63;
`;
const SpinnerContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 45%;
`;

const PageLoading = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <ModalPortal>
      <Container>
        <Backdrop>
          <SpinnerContainer>
            <SpinnerGrow size="5rem" />
          </SpinnerContainer>
        </Backdrop>
      </Container>
    </ModalPortal>
  );
};

PageLoading.propTypes = {};

export default PageLoading;
