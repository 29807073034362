import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "redux/auth/action";
import { firebaseApp } from "configuredFirebase";
import userAPI from "api/user";
import { ErrorReport } from "domain/Error/ErrorReport";
import {
  isPushNotifAsked,
  isPushNotifEnabled,
  markPushNotifAsNotAsked,
} from "AskPushNotifPermission";
import { getHomeUrl } from "utils/misc";

const Logout = ({ logout, accessToken, history }) => {
  useEffect(() => {
    async function run() {
      // NOTE: it will better embedded in backend logout logic instead of in frontend
      if (isPushNotifAsked()) {
        if (isPushNotifEnabled()) {
          await deletePushNotif();
        }
        markPushNotifAsNotAsked();
      }

      logout(accessToken).then(() => {
        history.push(getHomeUrl());
      });
    }

    const timeout = setTimeout(() => run(), 1000);
    return () => clearTimeout(timeout);
  }, []);

  return <p className="p-3 ff-mono text-dark">Sedang memproses logout...</p>;
};

Logout.propTypes = {};

const mapStateToProps = (state) => ({
  accessToken: state.auth.access_token,
});

export default connect(mapStateToProps, { logout })(Logout);

async function deletePushNotif() {
  try {
    const messaging = firebaseApp.messaging();
    const currentToken = await messaging.getToken();
    await messaging.deleteToken(currentToken);
    await userAPI.unsubscribePushNotif();
  } catch (err) {
    ErrorReport(err);
  }
}
