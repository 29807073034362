import {
  UPDATED,
  ADDRESS_UPDATED,
  MASS_UPDATED,
  CLEARED,
  DOCUMENT_ADDED,
  DOCUMENT_DELETED,
  PARENT_ADDED,
  PARENT_UPDATED,
  PARENT_DELETED,
  ACHIEVEMENT_ADDED,
  ACHIEVEMENT_UPDATED,
  ACHIEVEMENT_DELETED,
} from "./type";
import produce from "immer";

const INITIAL_STATE = {
  school_ids: [],
  citizen_national_identifier: "",
  school_national_identifier: "",
  name: "",
  gender: null,
  birth_regency_name: "",
  birth_at: "",
  phone: "",
  email: "",
  photo_id: null,
  address: {
    country_id: null,
    province_id: null,
    regency_id: null,
    district_id: null,
    village_id: null,
    postal_code: "",
    latitude: "",
    longitude: "",
    hamlet_number: "",
    neighbourhood_number: "",
    street: "",
  },
  documents: [],
  parents: [],
  institution_origin: {
    name: "",
    identifier: "",
  },
  achievements: [],
};

function reducer(state, action = {}) {
  switch (action.type) {
    case MASS_UPDATED: {
      const data = action.data;
      return {
        ...state,
        citizen_national_identifier: data.citizen_national_identifier,
        school_national_identifier: data.school_national_identifier,
        name: data.name,
        birth_at: data.birth_at,
        birth_regency_name: data.birth_regency_name,
        gender: data.gender,
        address: { ...state.address, street: data.street },
        institution_origin: data.institution_origin,
      };
    }
    case UPDATED: {
      state[action.key] = action.value;
      break;
    }
    case ADDRESS_UPDATED: {
      state.address[action.key] = action.value;
      break;
    }
    case DOCUMENT_ADDED: {
      state.documents = [
        ...state.documents,
        { ...action.document, uuid: action.uuid },
      ];
      break;
    }
    case DOCUMENT_DELETED: {
      state.documents = state.documents.filter(
        ({ uuid }) => uuid !== action.uuidToDelete
      );
      break;
    }
    case PARENT_ADDED: {
      state.parents = [
        ...state.parents,
        { ...action.parent, uuid: action.uuid },
      ];
      break;
    }
    case PARENT_UPDATED: {
      state.parents = state.parents.map((parent) => {
        if (parent.uuid !== action.uuidToUpdate) return parent;
        return {
          ...parent,
          ...action.data,
          uuid: action.uuidToUpdate,
        };
      });
      break;
    }
    case PARENT_DELETED: {
      state.parents = state.parents.filter(
        ({ uuid }) => uuid !== action.uuidToDelete
      );
      break;
    }
    case ACHIEVEMENT_ADDED: {
      state.achievements = [
        ...state.achievements,
        { ...action.achievement, uuid: action.uuid },
      ];
      break;
    }
    case ACHIEVEMENT_UPDATED: {
      state.achievements = state.achievements.map((achievement) => {
        if (achievement.uuid !== action.uuidToUpdate) return achievement;
        return {
          ...achievement,
          ...action.data,
          uuid: action.uuidToUpdate,
        };
      });
      break;
    }
    case ACHIEVEMENT_DELETED: {
      state.achievements = state.achievements.filter(
        ({ uuid }) => uuid !== action.uuidToDelete
      );
      break;
    }
    case CLEARED: {
      return { ...INITIAL_STATE };
    }
    default: {
      return state;
    }
  }
}

export default produce(reducer, INITIAL_STATE);
