import admissionAPIs from "./mockAPI/admission";
import institutionAPIs from "./mockAPI/institution";
import formulirAPIs from "./mockAPI/formulir";
import myFormulirAPIs from "./mockAPI/myformulir";
import myNotificationAPIs from "./mockAPI/mynotification";
import masterAPIs from "./mockAPI/master";
import areaAPIs from "./mockAPI/area";
import fileAPIs from "./mockAPI/file";
import userAPIs from "./mockAPI/user";

export const handlers = [
  ...admissionAPIs,
  ...institutionAPIs,
  ...formulirAPIs,
  ...myFormulirAPIs,
  ...myNotificationAPIs,
  ...masterAPIs,
  ...areaAPIs,
  ...fileAPIs,
  ...userAPIs,
];
