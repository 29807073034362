import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Alert = ({
  variant = "primary",
  icon = null,
  onClose = null,
  className = "",
  children,
  ...props
}) => (
  <div
    className={cx(
      className,
      "alert",
      `alert-${variant}`,
      icon && "alert-icon",
      onClose && "alert-dismissible"
    )}
    {...props}
  >
    {icon && <em className={`icon ni ni-${icon}`} />}
    {children}
    {onClose && <button type="button" className="close" onClick={onClose} />}
  </div>
);

Alert.propTypes = {

  icon: PropTypes.string,
  variant: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Alert;
