import React from "react";
import { Link } from "react-router-dom";

export default ({ link, icon, text, count = null }) => {
  if (link.startsWith("http")) {
    return (
      <li className="nk-menu-item">
        <a href={link} className="nk-menu-link" target="_blank">
          <span className="nk-menu-icon">
            <em className={"icon ni ni-" + icon}></em>
          </span>
          <span className="nk-menu-text">
            {text} {count && <span className="count">({count})</span>}
          </span>
        </a>
      </li>
    );
  }
  return (
    <li className="nk-menu-item">
      <Link to={link} className="nk-menu-link">
        <span className="nk-menu-icon">
          <em className={"icon ni ni-" + icon}></em>
        </span>
        <span className="nk-menu-text">
          {text} {count && <span className="count">({count})</span>}
        </span>
      </Link>
    </li>
  );
};
