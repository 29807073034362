import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  margin-left: -${(props) => props.gap}px;
  margin-right: -${(props) => props.gap}px;
  width: calc(100% + ${(props) => props.gap}px);

  & > * {
    margin: 0;
    margin-left: ${(props) => props.gap}px;
    margin-right: ${(props) => props.gap}px;
    margin-bottom: ${(props) => props.gap}px;
  }
`;

export default function Inline({ gap = 4, children, ...restProps }) {
  return (
    <Container gap={gap} {...restProps}>
      {children}
    </Container>
  );
}
