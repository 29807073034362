import React from "react";
import MainMenu from "./MainMenu";
import SmallMenu from "./SmallMenu";
import styled from "styled-components";
import { MobileMenuContext } from "components/Layouts/Subscription";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  @media only screen and (max-width: 999px) {
    & {
      transition: transform 0.4s ease;
    }
    &.show {
      transform: none;
    }
  }
`;

export default (props) => {
  const { close, isShow } = React.useContext(MobileMenuContext);

  return (
    <>
      <Container
        className={"nk-aside " + (isShow ? "show" : "")}
        onClick={close}
      >
        <div className="nk-sidebar-menu" data-simplebar>
          <ErrorBoundary fallback={<span></span>}>
            <MainMenu />
            <SmallMenu />
          </ErrorBoundary>
        </div>
        <div className="nk-aside-close">
          <a href="#" className="toggle" data-target="sideNav">
            <em className="icon ni ni-cross"></em>
          </a>
        </div>
      </Container>
      {isShow && <div className="toggle-overlay" data-target="sideNav"></div>}
    </>
  );
};
