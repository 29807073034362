import React from "react";

export default ({ link, text, count = null }) => (
  <li className="nk-menu-item">
    <a href={link} className="nk-menu-link">
      <span className="nk-menu-text">
        {text} {count && <span className="count">({count})</span>}
      </span>
    </a>
  </li>
);