import { SAVED } from "./type";
import moment from "moment-timezone";

const INITIAL_STATE = {
  token: null,
  createdAt: null,
};

function session(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SAVED:
      return {
        token: action.token,
        createdAt: moment().toISOString(),
      };
    default:
      return state;
  }
}

export default session;
