import React from "react";
import cx from "classnames";

export default React.forwardRef(
  (
    {
      className = "",
      variant = "primary",
      size = "md",
      block = false,
      dim = false,
      outline = false,
      round = false,
      renderLeft,
      renderRight,
      type = "button",
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    function handleClick() {
      onClick && onClick();
    }

    return (
      <button
        ref={ref}
        type={type}
        className={cx(
          "btn",
          `btn-${size}`,
          getVariant(variant, outline),
          variant === "link" && "btn-link",
          block && "btn-block",
          dim && "btn-dim",
          outline && "btn-outline",
          round && "btn-round",
          className && className
        )}
        onClick={handleClick}
        {...props}
      >
        {renderLeft && renderLeft()}
        {children}
        {renderRight && renderRight()}
      </button>
    );
  }
);

function getVariant(variant, outline) {
  if (!variant) return null;
  if (outline) {
    return "btn-outline-" + variant;
  }
  return "btn-" + variant;
}
