import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { hideErrorPopup } from "redux/page/action";
import { useDispatch } from "react-redux";
import usePreventModalScrollCollision from "hooks/usePreventModalScrollCollision";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export default function PageError() {
  usePreventModalScrollCollision();
  const dispatch = useDispatch();
  return (
    <ModalPortal>
      <Container>
        <div className="nk-content ">
          <div className="nk-block nk-block-middle wide-xs mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">
                <em className="ni ni-puzzle"></em>
              </h1>
              <h3 className="nk-error-title">Terjadi Kesalahan</h3>
              <p className="nk-error-text">
                Laporan dikirimkan kepada tim teknisi dan akan segera direspon.
                Terima Kasih.
              </p>
              <button
                type="button"
                // onClick={() => dispatch(hideErrorPopup())}
                onClick={() => window.location.reload()}
                className="btn btn-lg btn-primary mt-2"
              >
                Muat Ulang
              </button>
            </div>
          </div>
        </div>
      </Container>
    </ModalPortal>
  );
}
