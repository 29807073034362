import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export const INSTITUTIONS = [
  {
    id: 1,
    name: "Edu Office",
    photo:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
    address: {
      street: "Jl. Jalan Saja No. 99",
    },
  },
  {
    id: 2,
    name: "SMA 24 Bandung",
    photo:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
    address: null,
  },
  {
    id: 3,
    name: "SMA 8 Jambi",
    photo:
      "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
    address: {
      street: null,
    },
  },
];

const INSTITUTION = {
  id: 1,
  name: "EduOffice",
  photo:
    "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
  logo:
    "https://hatta.storage.edulogy.id/file/school/logo/2021/01/30/logo-edulogy.png",
  email: "school@edulogy.id",
  phone: "02200220",
  category: "private",
  address: {
    street: "Jl. Jalan Saja No. 99",
  },
  vision: null,
  mission: null,
  extracurriculars: [
    {
      id: 1,
      name: "Teknik Multimedia",
      thumbnail: "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    },
  ],
  galleries: [
    {
      id: 1,
      thumbnail: "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
      description: "lorem ipsum bla bla bal",
    },
  ],
  achievements: [
    {
      id: 1,
      name: "Teknik Multimedia",
      thumbnail: "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    },
  ],
};

const ANNOUNCEMENTS = [
  {
    id: 1,
    title: "PPDB Jenjang SMP Tahun 2020",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu quam mi. Vivamus aliquet lacinia libero nec luctus. Pellentesque eget justo ante. Suspendisse potenti. Curabitur vel augue in ipsum faucibus venenatis. Cras ut lacus sit amet ligula fringilla viverra non in libero. Phasellus aliquet, neque ut scelerisque tincidunt, lorem magna semper leo, eu fermentum velit massa sagittis nulla. Fusce ipsum dui, volutpat ut sodales ut, cursus eu sem. Donec ullamcorper efficitur ipsum, nec consectetur quam consectetur et. Curabitur id convallis felis. Sed eros lorem, porttitor sed tincidunt ac, porta sed ante. Quisque in magna ac magna tempor placerat sed ac eros. Donec maximus bibendum mattis. Donec eget sodales neque. Suspendisse laoreet, ex quis pellentesque ultricies, nisl nulla pellentesque lacus, sed accumsan ligula quam sit amet purus. Aliquam tincidunt vel risus eget gravida. ",
    visibility: "public",
    formated_visibility: "Publik",
    publication: "published",
    formated_publication: "Dipublikasi",
    thumbnail: "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    published_at: "2021-02-22T17:00:00.000Z",
    attachments: [
      {
        formated_type: "Dokumen",
        id: 2,
        type: "document",
        name: "Sample.pdf",
        extension: "pdf",
        size: 25000,
        url: "https://gahp.net/wp-content/uploads/2017/09/sample.pdf",
      },
    ],
  },
  {
    id: 2,
    title: "PPDB Jenjang SMP Tahun 2021",
    content: null,
    visibility: "public",
    formated_visibility: "Publik",
    publication: "published",
    formated_publication: "Dipublikasi",
    thumbnail: null,
    published_at: "2021-02-22T17:00:00.000Z",
    attachments: [
      {
        formated_type: "Dokumen",
        id: 2,
        type: "document",
        name: null,
        extension: null,
        size: null,
        url: "https://gahp.net/wp-content/uploads/2017/09/sample.pdf",
      },
    ],
  },
  {
    id: 3,
    title: "PPDB Jenjang SMP Tahun 2022",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu quam mi. Vivamus aliquet lacinia libero nec luctus. Pellentesque eget justo ante. Suspendisse potenti. Curabitur vel augue in ipsum faucibus venenatis. Cras ut lacus sit amet ligula fringilla viverra non in libero. Phasellus aliquet, neque ut scelerisque tincidunt, lorem magna semper leo, eu fermentum velit massa sagittis nulla. Fusce ipsum dui, volutpat ut sodales ut, cursus eu sem. Donec ullamcorper efficitur ipsum, nec consectetur quam consectetur et. Curabitur id convallis felis. Sed eros lorem, porttitor sed tincidunt ac, porta sed ante. Quisque in magna ac magna tempor placerat sed ac eros. Donec maximus bibendum mattis. Donec eget sodales neque. Suspendisse laoreet, ex quis pellentesque ultricies, nisl nulla pellentesque lacus, sed accumsan ligula quam sit amet purus. Aliquam tincidunt vel risus eget gravida. ",
    visibility: "public",
    formated_visibility: "Publik",
    publication: "published",
    formated_publication: "Dipublikasi",
    thumbnail: "https://admission.dev.edulogy.id/asset/image/background/frontpage/edupromo.jpg",
    published_at: "2021-02-22T17:00:00.000Z",
    attachments: [ ],
  },
];

export default [
  rest.get(`${BASE_URL}/v1/institution`, (req, res, ctx) => {
    const { limit = 99999, offset = 0 } = req.params;
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: INSTITUTIONS.slice(offset, limit),
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/institution/:id`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: INSTITUTION,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/announcement`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ANNOUNCEMENTS,
      })
    );
  }),
];
