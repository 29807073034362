import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export const FORMULIRS = {
  total_pages: 2,
  formulirs: [
    {
      id: "5f748a35f3d8b02e44b25f05",
      uuid: "123e4567-e89b-12d3-a456-426614174000",
      code: "16143128824940192",
      status_verification: "unprocess",
      status_approval: "unapproved",
      status_registration: "unregistered",
      formated_status_approval: "Tidak lulus",
      institution: {
        id: 1,
        name: "Dinas Kota Bandung",
        logo:
          "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg",
      },
      schools: [
        {
          id: 45,
          name: "SMA 24 Bandung",
          logo:
            "https://hatta.storage.edulogy.id/file/school/logo/2020/05/11/pp.jpg",
          status_approval: "unapproved",
          formated_status_approval: "Tidak lulus",
          total_distances: 2322,
        },
      ],
      identity: {
        name: "Raka Suryaardi Widjaja",
        birth_at: "2021-02-22T17:00:00.000Z",
      },
      total_achievement_scores: 150,
      created_at: "2021-02-22T17:00:00.000Z",
    },
  ],
};

export default [
  rest.get(`${BASE_URL}/v1/formulir`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: FORMULIRS,
      })
    );
  }),
];
